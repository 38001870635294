<script>
import {findForm as find} from '@/domain/form/services'
import DefaultMixin from '../mixin'
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import FormFields from "components/forms/components/Fields"
import FormTopics from "components/forms/components/Topics"

const filters = {
  id: null,
  active: [true],
  deleted: false,
  search: null,
}

export default {
  name: 'FormsEdit',
  mixins: [DefaultMixin, GlobalPersonMixin],
  components: {
    FormTopics,
    FormFields
  },
  data() {
    return {
      loading: true,
      form: null,
      tab: 'campos'
    }
  },
  watch: {
  },
  computed: {
  },
  beforeCreate() {
  },
  mounted() {
    this.$route.params.id && this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load() {
      this.loading = true
      this.form = null
      find(this.$route.params.id)
          .then(response => {
            console.log(response)
            this.loading = false
            this.form = response.data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Formulário',
    name: 'Form'
  }
}
</script>

<template>
  <div v-if="form && !loading" class="form-container wrapper-md">
    <p class="m-b-none font-10"><a @click="$router.go(-1)">Voltar à lista de formulários</a></p>
    <h4 class="title m-t-none">{{form.name}}</h4>

    <div class="m-t m-b-n-xxl">
      <u-btn @click="tab = 'campos'" label="Campos" class="bg-white" :class="{'font-bold bg-blue text-white': tab === 'campos'}" no-caps />
      <u-btn @click="tab = 'topicos'" label="Tópicos" class="bg-white" :class="{'font-bold bg-blue text-white': tab === 'topicos'}" no-caps />
    </div>
    <form-fields :form="form" v-if="tab === 'campos'" />
    <form-topics :form="form" v-if="tab === 'topicos'" />
  </div>
  <div class="vistoria-container wrapper-md" v-else>
    Aguarde enquanto carregamos os dados do formulário...
  </div>
</template>
