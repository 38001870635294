import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'sl.forms.topic'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'name', active: true, sortable: true, ordering: 2},
    {label: 'Posição', name: 'order', active: true, sortable: true, ordering: 3},
    {label: 'Ativo', name: 'active', active: true, sortable: true, ordering: 4},
], listVersion)
